import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue, idsParametros) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    apiCalls.push({ name: 'selectTipoTelefono', method: 'ttelefono.select', params: { filter: apiFilterEstadoActivo } })
    // Parámetros
    const apiFilterParamertos = new APIFilter()
    apiFilterParamertos.addIn('idparametro', idsParametros)
    apiCalls.push({ name: 'selectParametro', method: 'parametro.select', params: { filter: apiFilterParamertos } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id, idsistema) {
    const esContactoDeSistema = (
      !!idsistema &&
      (await Vue.$online.sistemaTelefono.esContactoDeSistema(id, idsistema))
    )
    if (esContactoDeSistema) {
      const apiFilter = new APIFilter()
      apiFilter
        .addExact('idcliente_telefono', id)
        .addExact('idsistema', idsistema)
      const resp = await Vue.$api.call('sistemaTelefono.select', { filter: apiFilter })
      return resp.data.result.dataset[0]
    } else {
      const apiFilter = new APIFilter()
      apiFilter
        .addExact('idcliente_telefono', id)
      const resp = await Vue.$api.call('clienteTelefono.select', { filter: apiFilter })
      return resp.data.result.dataset[0]
    }
  },
}
